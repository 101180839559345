import { USER_ACCESS_TYPE } from '~/Configurations/constants';

export const REQUIREMENT_VALUE_TYPE = {
  BOOLEAN: 'BOOLEAN',
  INTEGER_MAX: 'INTEGER_MAX',
  INTEGER_MIN: 'INTEGER_MIN',
};

export const DOCUMENT_METADATA_FIELD = {
  director_name: 'director_name',
  number: 'number',
};

export const TRANSPORTER_DOCUMENT_TYPE = {
  TDP: 'TDP',
  AKTA: 'AKTA',
  'SIUP/SIUJPT': 'SIUP/SIUJPT',
  SKT: 'SKT',
  BANK_ACCOUNT_BOOK: 'BANK_ACCOUNT_BOOK',
  NPWP: 'NPWP',
  KTP: 'KTP',
  KK: 'KK',
};

export const TRANSPORTER_DOCUMENT_RULENAME = {
  PERSONAL: 'PERSONAL_TRANSPORTER_ONBOARDING_L1',
  COMPANY: 'COMPANY_TRANSPORTER_ONBOARDING_L1',
};

export const TRANSPORTER_DOCUMENT_GROUPNAME = {
  MAIN_DOCUMENT: 'MAIN_DOCUMENT',
  SECONDARY_DOCUMENT: 'SECONDARY_DOCUMENT',
};

export const TRANSPORTER_DOCUMENT_FIELD_TYPE = {
  MANDATORY: 'MANDATORY',
  OPTIONAL: 'OPTIONAL',
};

export const TRANSPORTER_DOCUMENT_STATUSES = {
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  DRAFT: 'DRAFT',
  REJECTED: 'REJECTED',
};

export const API_TRANSPORTER_ACTION = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

export const TRANSPORTER_DOCUMENT_STATUS_COLOR = {
  [TRANSPORTER_DOCUMENT_STATUSES.ACCEPTED]: 'success',
  [TRANSPORTER_DOCUMENT_STATUSES.REJECTED]: 'danger',
  [TRANSPORTER_DOCUMENT_STATUSES.PENDING]: 'warning',
};

export const ADD_EDIT_TRANSPORTER_ELIGIBLE_ROLES = [
  USER_ACCESS_TYPE.FULL_ACCESS,
  USER_ACCESS_TYPE.VENDOR_MANAGEMENT,
];
