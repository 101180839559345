import moment from 'moment';
import { BUSINESS_LINES_LABEL } from '~/Configurations/constants/midMile/tip';

export const TOP_OPTIONS = [
  { label: 'N/A', value: '{ "start": null, "end": null }' },
  { label: 'Less than or equal to 30 days', value: '{ "start": 0, "end": 30 }' },
  { label: 'More than 30 days', value: '{ "start": 31, "end": null }' },
];

export const BUSINESS_LINE = {
  SME: 'SME',
  ENTERPRISE: 'ENTERPRISE',
};

export const BUSINESS_LINE_OPTIONS = Object.keys(BUSINESS_LINES_LABEL).map(e => ({
  value: e,
  label: BUSINESS_LINES_LABEL[e],
}));

export const TRANSPORTER_KARGO_WALLET_LEDGER_CATEGORY = {
  MONEY_IN: 'MONEY_IN',
  MONEY_OUT: 'MONEY_OUT',
};

export const TRANSPORTER_KARGO_WALLET_LEDGER_STATUS = {
  DISBURSED: 'DISBURSED',
  ADDED_TO_WALLET: 'ADDED_TO_WALLET',
  MONEY_IN_REVERTED: 'MONEY_IN_REVERTED',
  MONEY_OUT_CANCELLED: 'MONEY_OUT_CANCELLED',
};

export const TRANSPORTER_KARGO_WALLET_LEDGER_STATUS_COLORS = {
  [TRANSPORTER_KARGO_WALLET_LEDGER_STATUS.DISBURSED]: 'success',
  [TRANSPORTER_KARGO_WALLET_LEDGER_STATUS.ADDED_TO_WALLET]: 'primary',
  [TRANSPORTER_KARGO_WALLET_LEDGER_STATUS.MONEY_IN_REVERTED]: 'danger',
  [TRANSPORTER_KARGO_WALLET_LEDGER_STATUS.MONEY_OUT_CANCELLED]: 'danger',
};

export const TRANSPORTER_KARGO_WALLET_LEDGER_SEARCH_TYPE = {
  BANK_DESTINATION_NUMBER: 'BANK_DESTINATION_NUMBER',
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
  DO_NUMBER: 'DO_NUMBER',
  INVOICE_NUMBER: 'INVOICE_NUMBER',
  PR_NUMBER: 'PR_NUMBER',
  TRANSACTION_NUMBER: 'TRANSACTION_NUMBER',
};

const INDONESIA_TIMEZONE = {
  '+0700': 'WIB',
  '+0800': 'WITA',
  '+0900': 'WIT',
};

const currentTimezone = INDONESIA_TIMEZONE[moment().format('ZZ')];
export const DATE_TIME_FORMAT = `DD MMM YYYY, HH:mm [${currentTimezone}]`;
export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = `HH:mm [${currentTimezone}]`;

export const DISBURSEMENT_PROCESS_ENUM = {
  INSTANT: 'INSTANT',
  THURSDAY: 'THURSDAY',
  WEEKLY: 'WEEKLY',
};

export default {};
