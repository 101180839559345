import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { APP_CONTEXT_STORAGE_KEY } from '~/Configurations/constants';
import Routes from './Configurations/Routes';
import AppWrapper from './Components/AppWrapper';

require('dotenv').config();

localStorage.setItem(APP_CONTEXT_STORAGE_KEY, 'BROKERAGE');

export default function App() {
  return (
    <AppWrapper>
      <Router>
        <Routes />
      </Router>
    </AppWrapper>
  );
}
