import { formatDateWIB } from '@kargotech/tms-common/Utilities/formatters';

/**
 * Shipment Analysis Log Feature Names (Enum returned from BE)
 */
export const SHIPMENT_ANALYSIS_LOG_FEATURES = {
  APP_GPS_AVAILABILITY: 'APP_GPS_AVAILABILITY',
  APP_GPS_LOCATION: 'APP_GPS_LOCATION',
  IMAGE_METADATA_DATETIME: 'IMAGE_METADATA_DATETIME',
  IMAGE_METADATA_LOCATION: 'IMAGE_METADATA_LOCATION',
  TRUCK_GPS_AVAILABILITY: 'TRUCK_GPS_AVAILABILITY',
  TRUCK_GPS_LOCATION: 'TRUCK_GPS_LOCATION',
};

/**
 * Shipment Analysis Log Action Name (Enum returned from BE)
 */
export const LOG_ACTION_NAME = {
  SUBMIT_POD: 'SUBMIT_POD',
  SUBMIT_POL: 'SUBMIT_POL',
};

/**
 * Fraud Score Section Name - Used to determine carousel group header
 */
export const FRAUD_SCORE_SECTION_NAME = {
  IMAGE_METADATA: 'IMAGE_METADATA',
  LOCATION_MATCHING: 'LOCATION_MATCHING',
};

/**
 * Fraud Score Feature Group Name - Used to determine subsection/group (Bolded) for fraud scoring
 */
export const FRAUD_SCORE_GROUP_NAME = {
  [FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA]: {
    DATE_AND_TIME: 'DATE_AND_TIME',
    DEVICE: 'DEVICE',
    IMAGE_LOCATION: 'IMAGE_LOCATION',
  },
  [FRAUD_SCORE_SECTION_NAME.LOCATION_MATCHING]: {
    DEVICE_GPS: 'DEVICE_GPS',
    TRUCK_GPS: 'TRUCK_GPS',
  },
};

/**
 * Enum for 'availability status' feature
 */
export const AVAILABILITY_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const DISTANCE_UNIT = {
  KILOMETER: 'km',
  METER: 'm',
};

/**
 * Enum for 'radius category' feature
 */
export const RADIUS_CATEGORY = {
  FAR: 'FAR',
  NEAR: 'NEAR',
  VERY_FAR: 'VERY_FAR',
  VERY_NEAR: 'VERY_NEAR',
};

const NOT_AVAILABLE = 'N/A';

const DISTANCE_KILOMETER = 1000;

/**
 * Construct distance data depending on distance size
 * @param {Number} data - distance to warehouse in meter
 * @returns {String} distance to warehouse with units in string format (rounded off down)
 */
const constructDistanceData = data => {
  if (Number(data) >= DISTANCE_KILOMETER) {
    return String(Math.floor(Number(data) / DISTANCE_KILOMETER)).concat(` ${DISTANCE_UNIT.KILOMETER}`);
  }

  return String(Math.floor(Number(data))).concat(` ${DISTANCE_UNIT.METER}`);
};

/**
 * Construct timestamp related data
 * @param {DateTime} data - UTC datetime
 * @returns {DateTime/String} constructed timestamp data
 */
const constructTimestampData = data => {
  if (data) {
    return formatDateWIB(data);
  }

  return NOT_AVAILABLE;
};

/**
 * Take filtered log based on submit POD/POL, construct and populate array with required data
 * and return populated data for the fraud score report
 * @param {ArrayList<Object>} analysisLogs - Shipment Analysis Logs that is filtered by action name
 * @param {Function} t - translation function
 * @param {Object} currentViewedImage - Optional object of the viewed document image
 * @return {ArrayList<Object>} Array of Objects that is required for the fraud score report
 */
export const populateFraudScoreReport = (analysisLogs = [], t, currentViewedImage) => {
  const currentImageSourceKsuid = currentViewedImage?.ksuid || undefined;
  const filteredAnalysisLogsImage = analysisLogs?.filter(
    log => (currentImageSourceKsuid
      ? log?.shipmentAnalysisReference?.sourceKsuid === currentImageSourceKsuid
      : log),
  );

  // Filtering required data
  const truckGpsAvail = analysisLogs?.find(log => log.featureName
    === SHIPMENT_ANALYSIS_LOG_FEATURES.TRUCK_GPS_AVAILABILITY)
    ?.featureMetadata?.availability;
  const truckGpsRadius = analysisLogs?.find(log => log.featureName
    === SHIPMENT_ANALYSIS_LOG_FEATURES.TRUCK_GPS_LOCATION)
    ?.featureMetadata?.radiusCategory;
  const truckGpsDistance = analysisLogs?.find(log => log.featureName
    === SHIPMENT_ANALYSIS_LOG_FEATURES.TRUCK_GPS_LOCATION)
    ?.featureMetadata?.warehouseDistance;
  const appGpsAvail = analysisLogs?.find(log => log.featureName
    === SHIPMENT_ANALYSIS_LOG_FEATURES.APP_GPS_AVAILABILITY)
    ?.featureMetadata?.availability;
  const appGpsRadius = analysisLogs?.find(log => log.featureName
    === SHIPMENT_ANALYSIS_LOG_FEATURES.APP_GPS_LOCATION)
    ?.featureMetadata?.radiusCategory;
  const appGpsDistance = analysisLogs?.find(log => log.featureName
    === SHIPMENT_ANALYSIS_LOG_FEATURES.APP_GPS_LOCATION)
    ?.featureMetadata?.warehouseDistance;
  const imageMetadataTimestamp = filteredAnalysisLogsImage?.find(log => log.featureName
    === SHIPMENT_ANALYSIS_LOG_FEATURES.IMAGE_METADATA_DATETIME)
    ?.featureMetadata?.messageTimestamp;
  const imageMetadataRadius = filteredAnalysisLogsImage?.find(log => log.featureName
    === SHIPMENT_ANALYSIS_LOG_FEATURES.IMAGE_METADATA_LOCATION)
    ?.featureMetadata?.radiusCategory;
  const imageMetadataDistance = filteredAnalysisLogsImage?.find(log => log.featureName
    === SHIPMENT_ANALYSIS_LOG_FEATURES.IMAGE_METADATA_LOCATION)
    ?.featureMetadata?.warehouseDistance;
  const imageMetadataMake = filteredAnalysisLogsImage?.find(log => log.featureName
    === SHIPMENT_ANALYSIS_LOG_FEATURES.IMAGE_METADATA_DATETIME)
    ?.featureMetadata?.make;
  const imageMetadataModel = filteredAnalysisLogsImage?.find(log => log.featureName
    === SHIPMENT_ANALYSIS_LOG_FEATURES.IMAGE_METADATA_DATETIME)
    ?.featureMetadata?.model;

  // Array filled accordingly when more fraud score data is added
  return [
    {
      key: FRAUD_SCORE_SECTION_NAME.LOCATION_MATCHING,
      name: t(`common:fraud_score_item_list:${FRAUD_SCORE_SECTION_NAME.LOCATION_MATCHING}`),
      itemRatingList: [
        {
          key: FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.LOCATION_MATCHING].TRUCK_GPS,
          name: t(`common:fraud_score_item_rating:${
            FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.LOCATION_MATCHING].TRUCK_GPS
          }`),
          dataList: [
            // Status
            {
              isStatus: true,
              label: t('common:status'),
              primaryValue: truckGpsAvail ? AVAILABILITY_STATUS.ACTIVE : AVAILABILITY_STATUS.INACTIVE,
            },
            // Radius Category
            {
              label: t('common:radius'),
              primaryValue: truckGpsRadius
                ? t(`fraud:radius_category:${truckGpsRadius}`)
                : t('fraud:radius_category:default'),
              secondaryValue: constructDistanceData(truckGpsDistance),
            },
          ],
        },
        {
          key: FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.LOCATION_MATCHING].DEVICE_GPS,
          name: t(`common:fraud_score_item_rating:${
            FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.LOCATION_MATCHING].DEVICE_GPS
          }`),
          dataList: [
            // Status
            {
              isStatus: true,
              label: t('common:status'),
              primaryValue: appGpsAvail ? AVAILABILITY_STATUS.ACTIVE : AVAILABILITY_STATUS.INACTIVE,
            },
            // Radius Category
            {
              label: t('common:radius'),
              primaryValue: appGpsRadius
                ? t(`fraud:radius_category:${appGpsRadius}`)
                : t('fraud:radius_category:default'),
              secondaryValue: constructDistanceData(appGpsDistance),
            },
          ],
        },
      ],
    },
    {
      key: FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA,
      name: t(`common:fraud_score_item_list:${FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA}`),
      itemRatingList: [
        {
          key: FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA].DATE_AND_TIME,
          name: t(`common:fraud_score_item_rating:${
            FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA].DATE_AND_TIME
          }`),
          dataList: [
            // Timestamp
            {
              label: t('common:timestamp'),
              primaryValue: constructTimestampData(imageMetadataTimestamp),
            },
          ],
        },
        {
          key: FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA].IMAGE_LOCATION,
          name: t(`common:fraud_score_item_rating:${
            FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA].IMAGE_LOCATION
          }`),
          dataList: [
            // Radius Category
            {
              label: t('common:radius'),
              primaryValue: imageMetadataRadius
                ? t(`fraud:radius_category:${imageMetadataRadius}`)
                : t('fraud:radius_category:default'),
              secondaryValue: constructDistanceData(imageMetadataDistance),
            },
          ],
        },
        {
          key: FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA].DEVICE,
          name: t(`common:fraud_score_item_rating:${
            FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA].DEVICE
          }`),
          dataList: [
            // Device Make
            {
              label: t('common:make'),
              primaryValue: imageMetadataMake,
            },
            // Device Model
            {
              label: t('common:model'),
              primaryValue: imageMetadataModel,
            },
          ],
        },
      ],
    },
  ];
};
