import { INDONESIA_TIMEZONE } from '~/Configurations/constants';

export const CLAIMS_TYPE = [
  'claimDamage',
  'claimMissing',
  'claimPackagingDamage',
  'claimNearExpiryDate',
  'claimWrongGoods',
  'claimTooMany',
  'claimTooFew',
];

export const claimTypeToLabel = t => ({
  claimDamage: t('common:damage'),
  claimMissing: t('common:missing_goods'),
  claimPackagingDamage: t('common:packaging_damage'),
  claimNearExpiryDate: t('common:goods_near_expiry_date'),
  claimWrongGoods: `${t('common:shipper_fault')} - ${t('common:wrong_goods')}`,
  claimTooMany: `${t('common:shipper_fault')} - ${t('common:goods_too_many')}`,
  claimTooFew: t('common:goods_too_few'),
});

export const claimToObjectWithFormAttribute = claimType => ({
  claimType,
  isReturnGoods: false,
  returnFeeOption: undefined,
  rejectionCategory: undefined,
  returnDeadline: undefined,
});

export const SHOW_CLAIMS_TO_LABEL = {
  BERITA_ACARA: 'BERITA_ACARA',
  POD: 'POD',
  LOAD_ITEMS: 'LOAD_ITEMS',
};

const currentTimezone = INDONESIA_TIMEZONE['+0700'];
export const DATE_TIME_FORMAT = `DD MMM YYYY, HH:mm [${currentTimezone}]`;

export default CLAIMS_TYPE;
