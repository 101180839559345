export const RECEIVED_LOCATIONS_MAP = {
  BANDUNG_MARGAHAYU_OFFICE: 'Bandung - Margahayu Office',
  CIKATOMAS_OFFICE: 'Cikatomas Office',
  PITSTOP_BAWEAN_SEMARANG: 'Pitstop Bawean Semarang',
  PITSTOP_CIBITUNG: 'Pitstop Cibitung',
  PITSTOP_PANDAAN: 'Pitstop Pandaan',
  PITSTOP_TANJUNG_PERAK: 'Pitstop Tanjung Perak',
  PITSTOP_TANJUNG_PRIOK: 'Pitstop Tanjung Priok',
  PLAZA_ASIA_OFFICE: 'Plaza Asia Office',
};
export const RECEIVED_LOCATIONS_SELECT_OPTIONS = Object.keys(RECEIVED_LOCATIONS_MAP).map(key => ({
  label: RECEIVED_LOCATIONS_MAP[key],
  value: key,
}));

export const DOCUMENT_TYPE_ADDITIONAL_DOCUMENT = {
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  CLAIM_AND_RETURN: 'CLAIM_AND_RETURN',
  OVERNIGHT_LOADING: 'OVERNIGHT_LOADING',
  OVERNIGHT_UNLOADING: 'OVERNIGHT_UNLOADING',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OTHER_FEE: 'OTHER_FEE',
  OTHER_LOCATION_FEE: 'OTHER_LOCATION_FEE',
};
