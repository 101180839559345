import {
  DERIVED_SHIPMENT_STATUSES,
  DOCUMENT_TYPE_ADD_DOCUMENT,
  LOCATION_FEE_TYPE,
  SHIPMENT_POINT_STATUS,
  SHIPMENT_STATUSES,
} from '~/Configurations/constants';

export const TOTAL_HOURS_IN_A_DAY = 24;
export const TIME_WINDOW_DURATION = 2;
export const PBI_METADATA = 'is_pbi';
export const DO_TYPES = {
  SME: 'SME',
  ENTERPRISE: 'ENTERPRISE',
  PODF: 'PODF',
};
export const DOCUMENT_TYPES = {
  POD: 'POD',
  POL: 'POL',
  CLAIMS_AND_RETURNS: 'CAR',
  CLAIM_AND_RETURN_DOCUMENT: 'CLAIM_AND_RETURN_DOCUMENT',
  CLAIM_AND_RETURN_GOODS: 'CLAIM_AND_RETURN_GOODS',
  DRIVER_REJECTED_GOODS: 'REJECTED_GOODS',
  DRIVER_REJECTION_LETTER: 'REJECTION_LETTER',
  PROOF_OF_APPROVAL_LOWER_PRICE: 'PROOF_OF_APPROVAL_LOWER_PRICE',
};
export const ROUTE_TYPE = {
  PICKUP: 'PICKUP',
  DROPOFF: 'DROPOFF',
};
export const hasPbiMetadata = metadata => {
  if (!metadata) {
    return false;
  }
  if (typeof metadata === 'object') {
    return metadata[PBI_METADATA];
  }
  try {
    const { [PBI_METADATA]: isPbi } = JSON.parse(metadata);
    return isPbi;
  } catch (_) {
    return false;
  }
};
export const getDerivedShipmentQueueingStatus = derivedStatuses => derivedStatuses
  .find(({ status: derivedStatus }) => [
    DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_ORIGIN,
    DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_DESTINATION,
  ].includes(derivedStatus)) || ({});
export const getDerivedShipmentQueueingOvertimeStatus = derivedStatuses => derivedStatuses
  .find(({ status: derivedStatus }) => [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME].includes(derivedStatus)) || ({});
const {
  ASSIGNED,
  UPCOMING,
  PLANNED,
  CANCELLED_BY_SHIPPER,
  CANCELLED_BY_TRANSPORTER,
  UNFULFILLED,
  REASSIGNED_BY_SHIPPER,
  REJECTED_BY_TRANSPORTER,
  CONFIRMATION_EXPIRED,
  ...SHIPMENT_ACTIVITY_LOG_STATUSES
} = SHIPMENT_STATUSES;
export { SHIPMENT_ACTIVITY_LOG_STATUSES };
export const SHIPMENT_POINT_TYPE = {
  PICKUP: 'PICKUP',
  DROPOFF: 'DROPOFF',
};
export const BTMS_SINGLE_SHIPMENT_TIMESTAMP_STATUS_SEQUENCE = [
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
  SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
];
export const BTMS_MULTI_SHIPMENT_TIMESTAMP_STATUS_SEQUENCE = {
  [SHIPMENT_POINT_TYPE.PICKUP]: [
    SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
  ],
  [SHIPMENT_POINT_TYPE.DROPOFF]: [
    SHIPMENT_STATUSES.ONGOING_TO_DESTINATION,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
    SHIPMENT_STATUSES.DOCUMENT_UPLOADED,
  ],
  LAST_POINT: [SHIPMENT_POINT_STATUS.COMPLETE],
};
export const BTMS_CURRENTLY_ONGOING_SHIPMENT_STATUSES = BTMS_SINGLE_SHIPMENT_TIMESTAMP_STATUS_SEQUENCE;
export const BTMS_TERMINAL_SHIPMENT_STATUSES = [
  SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
  SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER,
  SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER,
  SHIPMENT_STATUSES.CANCELLED,
  SHIPMENT_STATUSES.UNFULFILLED,
];
export const SHIPMENT_POINT_STATUSES_TOTAL_ORDER = [
  SHIPMENT_STATUSES.PENDING,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ONGOING_TO_DESTINATION,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.DOCUMENT_UPLOADED,
  SHIPMENT_POINT_STATUS.COMPLETE,
];

export const STATUS_SEQUENCE = [
  SHIPMENT_ACTIVITY_LOG_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_ACTIVITY_LOG_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_ACTIVITY_LOG_STATUSES.START_LOADING,
  SHIPMENT_ACTIVITY_LOG_STATUSES.FINISH_LOADING,
  SHIPMENT_ACTIVITY_LOG_STATUSES.ON_SHIPMENT,
  SHIPMENT_ACTIVITY_LOG_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_ACTIVITY_LOG_STATUSES.START_UNLOADING,
  SHIPMENT_ACTIVITY_LOG_STATUSES.FINISH_UNLOADING,
  SHIPMENT_ACTIVITY_LOG_STATUSES.WAITING_FOR_CONFIRMATION,
  SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_COMPLETE,
];
export const POL_STATUSES = STATUS_SEQUENCE.slice(0, 5);
export const POD_STATUSES = STATUS_SEQUENCE.slice(5);
export const CLAIM_RETURN_TYPE = {
  CLAIM: 'CLAIM',
  RETURN: 'RETURN',
};
export const VENDOR_ASSIGNMENT_TYPE = {
  BROADCAST_CONTRACTED: 'BROADCAST_CONTRACTED',
  PRIVATE_BIDDING: 'PRIVATE_BIDDING',
  SINGLE_CONTRACTED: 'SINGLE_CONTRACTED',
};
export const BROKER_PODC_RECIPIENT_TYPE = {
  DOORING_AGENT: 'DOORING_AGENT',
  AR: 'AR',
  SHIPPER: 'SHIPPER',
};
export const COMPANY_SHIPMENT_SEARCH_TYPES = {
  FIRST_MILE: 'FIRST_MILE',
  LICENSE_PLATE_OR_SHIPMENT_NUMBER: 'LICENSE_PLATE_OR_SHIPMENT_NUMBER',
  LICENSE_PLATE_OR_SHIPMENT_NUMBER_OR_EXTERNAL_ID: 'LICENSE_PLATE_OR_SHIPMENT_NUMBER_OR_EXTERNAL_ID',
  NORMAL: 'NORMAL',
};
export const MAX_BID_LIMIT_TYPE = {
  VALUE: 'Rp',
  PERCENTAGE: '%',
};

export const SHIPMENT_SEARCH_TYPES = {
  DO_NUMBER: 'DO_NUMBER',
  LICENSE_PLATE: 'LICENSE_PLATE',
  EXTERNAL_ID: 'EXTERNAL_ID',
};

/* Date Range Picker */
const today = new Date();
const oneMonthAgo = new Date();
oneMonthAgo.setMonth(today.getMonth() - 1);
export const LAST_MONTH_DATE = {
  start: oneMonthAgo.toISOString(),
  end: today.toISOString(),
};

const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(today.getDate() - 6); // The count is following tms-ui's DateRangePicker past_seven_days
export const PAST_7_DAYS_DATE = {
  start: sevenDaysAgo.toISOString(),
  end: today.toISOString(),
};

const firstDayThreeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, 1);
const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
export const PAST_3_MONTHS_DATE = {
  start: firstDayThreeMonthsAgo.toISOString(),
  end: lastDayPrevMonth.toISOString(),
};

const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
export const THIS_MONTH_DATE = {
  start: firstDayOfMonth.toISOString(),
  end: today.toISOString(),
};

const firstDayPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
export const PAST_MONTH_DATE = {
  start: firstDayPrevMonth.toISOString(),
  end: lastDayPrevMonth.toISOString(),
};

const firstDayCurrentYear = new Date(today.getFullYear(), 0, 1);
export const THIS_YEAR_DATE = {
  start: firstDayCurrentYear.toISOString(),
  end: today.toISOString(),
};
/* End of Date Range Picker */

/* Document Return */
export const DIGITAL_DOCUMENT_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  NOT_UPLOADED: 'NOT_UPLOADED',
  DRAFT: 'DRAFT',
};

export const DIGITAL_DOCUMENT_STATUS_COLOR = {
  APPROVED: 'highlightGreen',
  REJECTED: 'primaryRed',
  PENDING: 'primary',
  NOT_UPLOADED: 'primaryRed',
  DRAFT: 'primaryRed',
};

export const PHYSICAL_DOCUMENT_STATUS = {
  RECEIVED: 'RECEIVED',
  NOT_RECEIVED: 'NOT_RECEIVED',
};

export const PHYSICAL_DOCUMENT_STATUS_COLOR = {
  RECEIVED: 'primary',
  NOT_RECEIVED: 'primaryRed',
};

export const REQUIRED_DOCUMENT_RETURN_TYPE = {
  REQUIRED_DOCUMENTS_FOR_RECEIVER_AT_DESTINATION: 'REQUIRED_DOCUMENTS_FOR_RECEIVER_AT_DESTINATION',
  REQUIRED_DOCUMENTS_TO_BE_RETURNED_TO_SHIPPER: 'REQUIRED_DOCUMENTS_TO_BE_RETURNED_TO_SHIPPER',
  REQUIRED_DOCUMENTS_TO_BE_ATTACHED_IN_INVOICE: 'REQUIRED_DOCUMENTS_TO_BE_ATTACHED_IN_INVOICE',
};

export const SHIPMENT_DETAIL_ROWS = [
  'shipperName',
  'shipmentNumber',
  'licensePlate',
  'origin',
  'destination',
  'shipmentDate',
  'priceRateType',
];
/* End of Document Return */

/* Additional Document */
export const DOCUMENT_TO_LOCATION_FEE_TYPE_MAP = {
  [DOCUMENT_TYPE_ADD_DOCUMENT.LOADING_FEE]: LOCATION_FEE_TYPE.LOADING,
  [DOCUMENT_TYPE_ADD_DOCUMENT.UNLOADING_FEE]: LOCATION_FEE_TYPE.UNLOADING,
  [DOCUMENT_TYPE_ADD_DOCUMENT.OTHER_LOCATION_FEE]: LOCATION_FEE_TYPE.OTHER,
};
/* End of Additional Document /*

/* Event Tracker */
export const EVENT_TRACKER_ELEMENT_ID = {
  BTN_ACTION_ADD_WHITELISTED_TRANSPORTER: 'BTN_ACTION_ADD_WHITELISTED_TRANSPORTER',
  BTN_ACTION_CANCEL_SHIPMENT: 'BTN_ACTION_CANCEL_SHIPMENT',
  BTN_ADD_WHITELISTED_TRANSPORTER: 'BTN_ADD_WHITELISTED_TRANSPORTER',
};

export const EVENT_TRACKER_FORM_NAME = {
  CREATE_SHIPMENT_FORM: 'CREATE_SHIPMENT_FORM',
  EDIT_SHIPMENT_FORM: 'EDIT_SHIPMENT_FORM',
};

export const EVENT_TRACKER_MODULE_NAME = {
  ALL_SHIPMENT_LIST: 'ALL_SHIPMENT_LIST',
  CANCEL_SHIPMENT_DIALOG: 'CANCEL_SHIPMENT_DIALOG',
  PLANNED_SHIPMENT_LIST: 'PLANNED_SHIPMENT_LIST',
  SHIPMENT_DETAILS: 'SHIPMENT_DETAILS',
  WHITELISTED_TRANSPORTER_DIALOG: 'WHITELISTED_TRANSPORTER_DIALOG',
};
/* End of Event Tracker */

export const EPOD_DOCUMENT_TYPE_ADD_DOCUMENT = {
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  CLAIM_AND_RETURN: 'CLAIM_AND_RETURN',
  OVERNIGHT_LOADING: 'OVERNIGHT_LOADING',
  OVERNIGHT_UNLOADING: 'OVERNIGHT_UNLOADING',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OTHER_FEE: 'OTHER_FEE',
  OTHER_LOCATION_FEE: 'OTHER_LOCATION_FEE',
};

export const JOB_RAW_SCOPE = {
  BTMS: 'BTMS',
  OFFLINE_DO: 'OFFLINE_DO',
  BULK_UPLOAD: 'BULK_UPLOAD',
};
