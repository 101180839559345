export const PAYMENT_REQUEST_VENDOR_DOCUMENT = {
  SKB: 'SKB',
  SPPKP: 'SPPKP',
  NPWP: 'NPWP',
  'SIUP/SIUJPT': 'SIUP/SIUJPT',
};

export const WHT_SELECTION_TYPE = {
  NONE: 'NONE',
  WHT23: 'WHT23',
  WHT21: 'WHT21',
  WHT15: 'WHT15',
};
